<template>
  <v-container>
    <v-row
      align="center"
      justify="center">
      <svg
        height="512pt"
        viewBox="0 -33 512 512"
        width="512pt"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="m512 10.300781v425.515625c0 5.6875-4.613281 10.304688-10.300781 10.304688h-491.398438c-5.6875 0-10.300781-4.617188-10.300781-10.304688v-425.515625c0-5.6875 4.613281-10.300781 10.300781-10.300781h491.398438c5.6875 0 10.300781 4.613281 10.300781 10.300781zm0 0"
          fill="#e3f4ff" /><path
            d="m512 10.300781v425.515625c0 5.6875-4.613281 10.304688-10.300781 10.304688h-30.566407c5.6875 0 10.300782-4.617188 10.300782-10.304688v-425.515625c0-5.6875-4.613282-10.300781-10.300782-10.300781h30.566407c5.6875 0 10.300781 4.613281 10.300781 10.300781zm0 0"
            fill="#bddff4" /><path
              d="m512 10.300781v55.1875h-512v-55.1875c0-5.6875 4.613281-10.300781 10.300781-10.300781h491.398438c5.6875 0 10.300781 4.613281 10.300781 10.300781zm0 0"
              fill="#618baa" /><path
                d="m512 10.300781v55.1875h-30.566406v-55.1875c0-5.6875-4.613282-10.300781-10.300782-10.300781h30.566407c5.6875 0 10.300781 4.613281 10.300781 10.300781zm0 0"
                fill="#4e7693" /><path
                  d="m150.292969 0v65.488281h-150.292969v-55.1875c0-5.6875 4.613281-10.300781 10.300781-10.300781zm0 0"
                  fill="#fd6096" /><path
                    d="m47.878906 40.390625c-.015625 0-.027344 0-.039062 0h-.109375c-4.265625-.023437-7.710938-3.496094-7.691407-7.761719.023438-4.257812 3.476563-7.691406 7.726563-7.691406h.039063.105468c4.269532.019531 7.710938 3.496094 7.691406 7.761719-.019531 4.253906-3.472656 7.691406-7.722656 7.691406zm0 0"
                    fill="#fc3e81" /><path
                      d="m76.21875 40.472656h-.109375c-4.265625 0-7.726563-3.460937-7.726563-7.726562s3.460938-7.726563 7.726563-7.726563h.109375c4.269531 0 7.726562 3.460938 7.726562 7.726563s-3.457031 7.726562-7.726562 7.726562zm0 0"
                      fill="#fc3e81" /><path
                        d="m104.5625 40.554688c-.011719 0-.023438 0-.035156 0h-.109375c-4.269531-.019532-7.710938-3.496094-7.691407-7.761719.019532-4.257813 3.476563-7.691407 7.726563-7.691407h.035156.109375c4.269532.019532 7.710938 3.496094 7.691406 7.761719-.019531 4.253907-3.472656 7.691407-7.726562 7.691407zm0 0"
                        fill="#fc3e81" /><g
                          fill="#93c0d1"><path
                          d="m212.390625 112.308594h-161.738281c-4.269532 0-7.726563-3.460938-7.726563-7.726563 0-4.269531 3.457031-7.726562 7.726563-7.726562h161.738281c4.265625 0 7.722656 3.457031 7.722656 7.726562.003907 4.265625-3.457031 7.726563-7.722656 7.726563zm0 0" /><path
                          d="m304.761719 112.308594h-22.664063c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.460937-7.726562 7.726562-7.726562h22.664063c4.269531 0 7.726562 3.457031 7.726562 7.726562 0 4.265625-3.460937 7.726563-7.726562 7.726563zm0 0" /><path
                          d="m356.957031 112.308594h-22.664062c-4.265625 0-7.726563-3.460938-7.726563-7.726563 0-4.269531 3.460938-7.726562 7.726563-7.726562h22.664062c4.269531 0 7.726563 3.457031 7.726563 7.726562 0 4.265625-3.457032 7.726563-7.726563 7.726563zm0 0" /><path
                          d="m409.152344 112.308594h-22.664063c-4.265625 0-7.722656-3.460938-7.722656-7.726563 0-4.269531 3.457031-7.726562 7.722656-7.726562h22.664063c4.269531 0 7.726562 3.457031 7.726562 7.726562 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0" /><path
                          d="m461.347656 112.308594h-22.664062c-4.265625 0-7.722656-3.460938-7.722656-7.726563 0-4.269531 3.457031-7.726562 7.722656-7.726562h22.664062c4.269532 0 7.726563 3.457031 7.726563 7.726562 0 4.265625-3.457031 7.726563-7.726563 7.726563zm0 0" /></g><path
          d="m193.242188 317.125h-123.445313c-5.691406 0-10.300781-4.609375-10.300781-10.300781v-123.445313c0-5.691406 4.609375-10.304687 10.300781-10.304687h123.445313c5.691406 0 10.304687 4.613281 10.304687 10.304687v123.445313c0 5.691406-4.613281 10.300781-10.304687 10.300781zm0 0"
          fill="#32dfb7" /><path
            d="m111.308594 226.644531 3.550781-3.550781c3.015625-3.015625 3.015625-7.90625 0-10.925781-3.019531-3.015625-7.910156-3.015625-10.929687 0l-3.550782 3.550781-3.550781-3.550781c-3.015625-3.015625-7.90625-3.015625-10.925781 0-3.015625 3.015625-3.015625 7.910156 0 10.925781l3.550781 3.550781-3.550781 3.550781c-3.015625 3.019532-3.015625 7.910157 0 10.929688 1.507812 1.507812 3.488281 2.261719 5.464844 2.261719 1.976562 0 3.953124-.753907 5.460937-2.261719l3.550781-3.550781 3.550782 3.550781c1.507812 1.507812 3.488281 2.261719 5.464843 2.261719 1.976563 0 3.953125-.753907 5.464844-2.261719 3.015625-3.019531 3.015625-7.910156 0-10.929688zm0 0"
            fill="#21c69c" /><path
              d="m173.585938 226.644531 3.550781-3.550781c3.015625-3.015625 3.015625-7.90625 0-10.925781-3.019531-3.015625-7.910157-3.015625-10.925781 0l-3.550782 3.550781-3.550781-3.550781c-3.019531-3.015625-7.910156-3.015625-10.929687 0-3.015626 3.015625-3.015626 7.910156 0 10.925781l3.550781 3.550781-3.550781 3.550781c-3.015626 3.019532-3.015626 7.910157 0 10.929688 1.511718 1.507812 3.488281 2.261719 5.464843 2.261719 1.976563 0 3.957031-.753907 5.464844-2.261719l3.550781-3.550781 3.550782 3.550781c1.507812 1.507812 3.484374 2.261719 5.460937 2.261719s3.957031-.753907 5.464844-2.261719c3.015625-3.019531 3.015625-7.910156 0-10.929688zm0 0"
              fill="#21c69c" /><path
                d="m171.671875 268.207031v43.433594c0 7.875-6.382813 14.253906-14.253906 14.253906-7.871094 0-14.253907-6.378906-14.253907-14.253906v-43.433594"
                fill="#fd6096" /><path
                  d="m171.671875 275.933594h-80.304687c-4.269532 0-7.726563-3.460938-7.726563-7.726563s3.457031-7.726562 7.726563-7.726562h80.304687c4.269531 0 7.726563 3.460937 7.726563 7.726562s-3.457032 7.726563-7.726563 7.726563zm0 0"
                  fill="#21c69c" /><path
                    d="m304.761719 232.320312c-4.269531 0-7.726563 3.457032-7.726563 7.726563v12.800781h-19.398437c-1.421875 0-2.578125-1.15625-2.578125-2.578125v-72.808593c0-4.265626-3.457032-7.726563-7.722656-7.726563-4.269532 0-7.726563 3.460937-7.726563 7.726563v72.808593c0 9.941407 8.085937 18.03125 18.027344 18.03125h19.398437v53.210938c0 4.265625 3.460938 7.726562 7.726563 7.726562 4.269531 0 7.726562-3.460937 7.726562-7.726562v-81.464844c0-4.269531-3.460937-7.726563-7.726562-7.726563zm0 0"
                    fill="#749db7" /><path
                      d="m427.867188 232.320312c-4.265626 0-7.726563 3.457032-7.726563 7.726563v12.800781h-19.402344c-1.417969 0-2.574219-1.15625-2.574219-2.578125v-72.808593c0-4.265626-3.457031-7.726563-7.726562-7.726563-4.265625 0-7.722656 3.460937-7.722656 7.726563v72.808593c0 9.941407 8.085937 18.03125 18.027344 18.03125h19.398437v53.210938c0 4.265625 3.460937 7.726562 7.726563 7.726562 4.269531 0 7.726562-3.460937 7.726562-7.726562v-81.464844c0-4.269531-3.457031-7.726563-7.726562-7.726563zm0 0"
                      fill="#749db7" /><path
                        d="m356.011719 329.238281h-16.824219c-9.941406 0-18.027344-8.089843-18.027344-18.027343v-123.449219c0-9.941407 8.085938-18.027344 18.027344-18.027344h16.824219c9.941406 0 18.027343 8.089844 18.027343 18.027344v123.449219c0 9.9375-8.085937 18.027343-18.027343 18.027343zm-16.824219-144.050781c-1.421875 0-2.574219 1.15625-2.574219 2.574219v123.449219c0 1.417968 1.152344 2.574218 2.574219 2.574218h16.824219c1.421875 0 2.578125-1.15625 2.578125-2.574218v-123.449219c0-1.417969-1.15625-2.574219-2.578125-2.574219zm0 0"
                        fill="#749db7" /><path
                          d="m327.769531 382.90625h-143.539062c-4.269531 0-7.726563-3.460938-7.726563-7.726562 0-4.269532 3.460938-7.726563 7.726563-7.726563h143.539062c4.269531 0 7.726563 3.457031 7.726563 7.726563 0 4.265624-3.460938 7.726562-7.726563 7.726562zm0 0"
                          fill="#93c0d1" /><path
                            d="m287.25 405.570312h-62.496094c-4.269531 0-7.726562-3.460937-7.726562-7.726562 0-4.269531 3.457031-7.726562 7.726562-7.726562h62.496094c4.265625 0 7.726562 3.457031 7.726562 7.726562 0 4.265625-3.460937 7.726562-7.726562 7.726562zm0 0"
                            fill="#93c0d1" />
      </svg>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'cc-404'
}
</script>

<style>

</style>
